import { CalendarDateRangeIcon, CalendarIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon, LockClosedIcon, ScaleIcon, WifiIcon } from '@heroicons/react/24/outline'
import COMPONENT_TEXTS from '@tebuto/translations'

export const featuresList = [
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.href,
        icon: ComputerDesktopIcon,
        image: '/assets/carousel/termine-buchen.webp',
        mobileImage: '/assets/carousel/termine-buchen-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.href,
        icon: CalendarDateRangeIcon,
        image: '/assets/carousel/terminmanagement.webp',
        mobileImage: '/assets/carousel/terminmanagement-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.href,
        icon: WifiIcon,
        image: '/assets/carousel/online-meetings.webp',
        mobileImage: '/assets/carousel/online-meetings-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.href,
        icon: ScaleIcon,
        image: '/assets/carousel/ausfallhonorar.webp',
        mobileImage: '/assets/carousel/ausfallhonorar-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.href,
        icon: CalendarIcon,
        image: '/assets/carousel/kalender.webp',
        mobileImage: '/assets/carousel/kalender-mobile.webp'
    },

    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.href,
        icon: ChatBubbleLeftRightIcon,
        image: '/assets/carousel/kommunikation.webp',
        mobileImage: '/assets/carousel/kommunikation-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.href,
        icon: LockClosedIcon,
        image: '/assets/carousel/datenschutz.webp',
        mobileImage: '/assets/carousel/datenschutz-mobile.webp'
    }
]
