'use client'

import { ArchiveBoxIcon, DocumentTextIcon } from '@heroicons/react/24/solid'
import { DocumentTextIcon as OutlineDocumentTextIcon } from '@heroicons/react/24/outline'
import { useLoginStatus } from '@tebuto/functions'
import COMPONENT_TEXTS from '@tebuto/translations'
import { Menu, MenuEntry } from '../Menu'
import { featuresList } from './features'
import { MapIcon } from '@heroicons/react/24/outline'
import { Building2, MailQuestionIcon, NotebookPenIcon, UsersIcon } from 'lucide-react'

export const Header = () => {
    const isLoggedIn = useLoginStatus()

    const leftItems: MenuEntry[] = [
        { name: COMPONENT_TEXTS.layout.landing.menu.home, href: '/' },
        {
            name: COMPONENT_TEXTS.layout.landing.menu.usage,
            href: '#',
            mobileHref: '/features',
            mobileBehaviour: 'titleOnly',
            subMenu: {
                layout: '2rows',
                items: featuresList.map(feature => {
                    return { ...feature, description: feature.subtitle }
                }),
                cta: [
                    { name: 'Alle Funktionen', href: '/features', icon: ArchiveBoxIcon },
                    { name: 'Dokumentation', href: '/dokumentation', icon: DocumentTextIcon }
                ]
            }
        },
        {
            name: 'Ressourcen',
            href: '#',
            mobileBehaviour: 'flat',
            subMenu: {
                items: [
                    { name: COMPONENT_TEXTS.layout.landing.menu.roadmap, href: '/roadmap', icon: MapIcon, description: 'Deine Vorschläge für eine bessere Software' },
                    { name: COMPONENT_TEXTS.layout.landing.menu.blog, href: '/blog', icon: NotebookPenIcon, description: 'Blog-Beiträge aus dem psychosozialen Bereich' },
                    { name: 'Dokumentation', href: '/dokumentation', icon: OutlineDocumentTextIcon, description: 'Alles, was du wissen musst' },
                    { name: 'Kontakt', href: '/kontakt', icon: MailQuestionIcon, description: 'Fragen oder Anregungen? Schreib uns!' }
                ]
            }
        },
        { name: COMPONENT_TEXTS.layout.landing.menu.price, href: '/preis' },
        { name: COMPONENT_TEXTS.layout.landing.menu.aboutUs, href: '/ueber-uns' }
    ]

    const rightItems: MenuEntry[] = []

    if (isLoggedIn) {
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.toApp, href: process.env.NEXT_PUBLIC_APP_BASE_URL as string, isAbsolute: true, highlight: true })
    } else {
        rightItems.push({
            name: COMPONENT_TEXTS.layout.landing.menu.login,
            href: '#',
            id: 'login',
            mobileBehaviour: 'default',
            subMenu: {
                items: [
                    {
                        name: 'Als Terminanbieter:in',
                        href: process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL as string,
                        icon: Building2,
                        description: 'Du möchtest Termine anbieten',
                        isAbsolute: true
                    },
                    {
                        name: 'Als Klient:in',
                        href: process.env.NEXT_PUBLIC_CLIENTS_AUTHORIZE_URL as string,
                        icon: UsersIcon,
                        description: 'Du möchtest einen Termin buchen',
                        isAbsolute: true
                    }
                ]
            }
        })
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.register, href: '/registrieren', highlight: true })
    }

    const additionalMobileItems = [{ name: 'Dokumentation', href: '/dokumentation' }]

    return <Menu id="top" leftItems={leftItems} rightItems={rightItems} baseUrl={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}`} additionalMobileItems={additionalMobileItems} />
}
